const overlayState = reactive<{
  id: string;
  isOpen: boolean;
}>({
  id: 'overlay',
  isOpen: false,
});

export const useOverlay = () => {
  function toggleOverlay(state?: boolean) {
    if (typeof state !== 'undefined') {
      overlayState.isOpen = state;

      return;
    }

    overlayState.isOpen = !overlayState.isOpen;
  }

  return {
    overlayState,
    toggleOverlay,
  };
};
